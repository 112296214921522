import { useCallback, useContext } from 'react';

import { SheetStackContext } from '@/components/display/SheetStack';
import TrackProgress from '@/components/display/StackViews/TrackProgress';
import { trpc } from '@/lib/trpc';
import { toTitleCase } from '@/lib/utils/frontend';

import CSVImport from '../display/StackViews/CSVImport';
import EventTracking from '../display/StackViews/EventTracking';

type views = 'progress-track' | 'csv-import' | 'event-tracker';

export default function useSharedStackView() {
  const { pushView, popView, setOpen } = useContext(SheetStackContext);
  const utils = trpc.useUtils();

  const handleInvalidate = useCallback(
    async (view: views) => {
      try {
        if (view === 'progress-track') {
          await utils.QProgressTrack.list.invalidate();
        }
      } catch {}
    },
    [utils.QProgressTrack.list],
  );

  const show = useCallback(
    (view: views) => {
      popView(view);
      handleInvalidate(view).then(() => {});
      pushView({
        id: view,
        name: toTitleCase(view.replaceAll('-', ' '))!,
        component: CompForView(view),
      });
      setOpen(true);
    },
    [pushView, popView, setOpen, handleInvalidate],
  );

  return {
    show,
  };
}

function CompForView(view: views) {
  switch (view) {
    case 'progress-track':
      return <TrackProgress />;
    case 'csv-import':
      return <CSVImport />;
    case 'event-tracker':
      return <EventTracking />;
  }
}
