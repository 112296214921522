import { useMemo, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import { useDebounce } from '@/components/hooks/useDebounce';
import { Input } from '@/components/ui/input';
import { trpc } from '@/lib/trpc';
import type { EventTrackingGroup } from '@/server/q/types';

import { Gauge } from '../Guage';

export default function EventTracking() {
  const [search, setSearch] = useState<string>();
  const searchValue = useDebounce(search, 200);

  const { data: trackingGroups } = trpc.listTrackingGroups.useQuery({ limit: 50, query: searchValue });

  return (
    <div className="space-y-3">
      <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
      <div className="flex flex-col gap-5">{trackingGroups?.map((group) => <TrackingGroup key={group.id} group={group} />)}</div>
    </div>
  );
}

function TrackingGroup(props: { group: EventTrackingGroup }) {
  const [group, setGroup] = useState(props.group);
  const { refetch } = trpc.fetchTrackingGroup.useQuery({ id: parseInt(props.group.id, 10) }, { enabled: false });
  const { mutate } = trpc.updateTrackingGroup.useMutation();

  const { waiting, processing, failed, completed } = group;

  const total = useMemo(() => {
    return waiting + processing + failed + completed;
  }, [waiting, processing, failed, completed]);

  return (
    <div className="border rounded-lg p-4 relative flex flex-col-reverse">
      <div className="flex justify-between items-center px-1">
        <ContentEditable
          className="block w-full text-lg mr-4"
          html={group.name ?? ''}
          onChange={(e) => {
            setGroup({ ...group, name: e.currentTarget.innerText });
            mutate({ id: parseInt(group.id, 10), name: e.currentTarget.innerText });
          }}
        />
        <div className="flex gap-2">
          {group.tags?.map((t) => (
            <span key={t} className="bg-slate-100/90 text-black rounded p-1 px-3 text-sm drop-shadow">
              {t}
            </span>
          ))}
        </div>
      </div>
      <div
        className="w-full flex justify-between items-center gap-4 mt-2 mb-6 cursor-pointer"
        onClick={async () => {
          const result = await refetch();
          result.data && setGroup(result.data);
        }}
      >
        <Gauge value={Math.ceil((waiting / total) * 100)} size="full" showValue color={'text-[var(--ds-blue-700)]'} bgcolor="text-[var(--ds-blue-100)]" />
        <Gauge value={Math.ceil((processing / total) * 100)} size="full" showValue color={'text-[var(--ds-amber-700)]'} bgcolor="text-[var(--ds-amber-100)]" />
        <Gauge value={Math.floor((failed / total) * 100)} size="full" showValue color={'text-[var(--ds-red-700)]'} bgcolor="text-[var(--ds-red-100)]" />
        <Gauge value={Math.ceil((completed / total) * 100)} size="full" showValue color={'text-green-600/90'} bgcolor="text-[var(--ds-green-100)]" />
      </div>
    </div>
  );
}
