import { useParams } from 'next/navigation';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import useEvent from '@/components/hooks/useEvent';
import useEventState from '@/components/hooks/useEventState';
import useResumableUpload from '@/components/hooks/useResumableUpload';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import MultipleSelector from '@/components/ui/multipleSelector';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { trpc } from '@/lib/trpc';
import { toTitleCase } from '@/lib/utils/frontend';
import type { ClientAccountsImportTypes } from '@/server/actions/dispatchImportClientAccounts';

export default function CSVImport() {
  const [eventId, setEventId] = useState<string>();

  const { mutate, isPending } = trpc.dispatchImportClientAccounts.useMutation({
    onSuccess: (data) => {
      setEventId(data.eventId);
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  const params = useParams();
  const clientName = params!['client'] as string;

  const [option, setOption] = useState<ClientAccountsImportTypes>('salesforce');
  const [tags, setTags] = useState<string[]>([]);
  const [dedupe, setDedupe] = useState(false);
  const [mixrankEnrich, setMixrankEnrich] = useState(false);
  const [urlVal, setUrlVal] = useState(false);
  const [fileUploadId, setFileUploadId] = useState<string>();

  const state = useEventState(eventId);
  const { data: event } = useEvent(eventId);
  const status = event?.status;
  const output = event?.output;

  let buttonText = 'Run Import';
  if (state === 'processing') {
    if (status) {
      buttonText = status;
    } else {
      buttonText = state;
    }
  } else if (eventId && state) {
    buttonText = state;
  }

  return (
    <div className="space-y-4 pb-24">
      <div className="space-y-6 pb-4">
        <div className="space-y-3 pt-3">
          <Label className="text-lg">These accounts: </Label>
          <RadioGroup defaultValue="salesforce" className="space-y-2" onValueChange={(v) => setOption(v as ClientAccountsImportTypes)}>
            <div className="flex items-center space-x-2 cursor-pointer">
              <RadioGroupItem value="salesforce" id="salesforce" />
              <Label htmlFor="salesforce" className="text-base">
                Have a valid Salesforce Id & are from a CRM{' '}
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="gen-id" id="gen-id" />
              <Label htmlFor="gen-id" className="text-base">
                Do not have an id & need an id generating
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="custom-id" id="custom-id" />
              <Label htmlFor="custom-id" className="text-base">
                Have a custom id column called custom_id
              </Label>
            </div>
          </RadioGroup>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-2 pt-1">
            <Checkbox id="dedupe" checked={dedupe} onCheckedChange={() => setDedupe((v) => !v)} className="h-5 w-5 rounded-sm" />
            <label htmlFor="dedupe" className="cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Simple URL Deduplication
            </label>
          </div>
          <div className="flex items-center space-x-2 pt-1">
            <Checkbox id="mixrankenrich" checked={mixrankEnrich} onCheckedChange={() => setMixrankEnrich((v) => !v)} className="h-5 w-5 rounded-sm" />
            <label htmlFor="mixrankenrich" className="cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Auto Run Mixrank Enrichment
            </label>
          </div>
          <div className="flex items-center space-x-2 pt-1">
            <Checkbox id="url-validation" checked={urlVal} onCheckedChange={() => setUrlVal((v) => !v)} className="h-5 w-5 rounded-sm" />
            <label htmlFor="url-validation" className="cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Auto Run URL Validation
            </label>
          </div>
        </div>
      </div>
      <CSVUpload
        onComplete={(fileId) => {
          setFileUploadId(fileId);
        }}
      />
      <div>
        <MultipleSelector
          value={tags.map((o) => ({ label: o, value: o }))}
          onChange={(v) => {
            const tags = v.map((v) => v.value);
            setTags(tags);
          }}
          placeholder={`Add tags...`}
          creatable
          emptyIndicator={<p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">type to create...</p>}
        />
      </div>
      <div>
        <Button
          disabled={!fileUploadId || !clientName || isPending || eventId !== undefined}
          onClick={() => {
            mutate({
              client: clientName!,
              csvId: fileUploadId!,
              type: option,
              dedupe,
              mixrankEnrich: mixrankEnrich,
              validateUrls: urlVal,
              tags,
            });
          }}
        >
          {buttonText}
        </Button>
      </div>
      {output && (
        <div className="pt-4">
          {Object.keys(output).map((key) => {
            return (
              <div key={key} className="w-full pb-2 flex justify-between items-center">
                <span>{toTitleCase(key.replaceAll('_', ' '))}</span>
                <span>{output[key]}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function CSVUpload(props: { onComplete: (fileId: string) => void }) {
  const [uploadId] = useState(uuidv4());

  const { upload, progress } = useResumableUpload('momentum-supabase-prod-csv-import', `${uploadId}.csv`, () => {
    props.onComplete(uploadId);
  });

  let status = 'Upload a CSV';
  if (progress > 0 && progress < 100) status = `Uploading CSV (${progress}%)`;
  if (progress === 100) status = 'CSV Uploaded ✅';

  return (
    <div
      className="border border-dashed rounded bg-slate-900/60 flex items-center justify-center h-[100px] cursor-pointer hover:bg-slate-900/30 transition-all"
      onClick={() => {
        document.getElementById('import_csv_input_import_accounts')?.click();
      }}
    >
      <span className="block">{status}</span>
      <Input
        type="file"
        name="csv_input"
        id="import_csv_input_import_accounts"
        hidden
        style={{ display: 'none' }}
        onChange={() => {
          const csv = (document.getElementById('import_csv_input_import_accounts') as HTMLInputElement).files?.[0];
          const reader = new FileReader();
          reader.onload = () => {
            if (csv) {
              upload(csv);
            }
          };
          reader.readAsText(csv!);
        }}
      />
    </div>
  );
}
