import { useCallback, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { trpc } from '@/lib/trpc';

type UploadedPart = { ETag: string; PartNumber: number };

export default function useResumableUpload(bucketName: string, path: string, complete: () => void) {
  const { refetch: create } = trpc.s3.multipart.create.useQuery({ bucketName, path }, { enabled: false });
  const { mutate: completeUpload } = trpc.s3.multipart.complete.useMutation({
    onSuccess: () => {
      complete();
    },
    onError: () => {
      alert('Could not complete file upload');
    },
  });

  const { mutate: abort } = trpc.s3.multipart.abort.useMutation();
  const [parts, setParts] = useState<Blob[]>([]);
  const [progress, setProgress] = useState(0);

  const { mutate: getPartUrls } = trpc.s3.multipart.getPartUrls.useMutation({
    onSuccess: async (partUrls, inputs) => {
      const uploadedParts: UploadedPart[] = [];

      for (const [index, partUrl] of partUrls.entries()) {
        const response = await fetch(partUrl.url, {
          method: 'PUT',
          body: parts[index]!,
        });

        if (!response.ok) {
          abort({ bucketName, path, uploadId: inputs.uploadId });
          alert(`Failed to upload part ${index + 1}`);
          throw new Error(`Failed to upload part ${index + 1}`);
        }

        uploadedParts.push({
          ETag: response.headers.get('ETag')!,
          PartNumber: index + 1,
        });

        setProgress(Math.ceil((uploadedParts.length / parts.length) * 100));
      }

      completeUpload({ bucketName, path, uploadId: inputs.uploadId, parts: uploadedParts });
    },
    onError: (error, inputs) => {
      abort({ bucketName, path, uploadId: inputs.uploadId });
      alert(`Issue uploading file parts: ${error.message}`);
    },
  });

  const startUpload = useCallback(
    async (file: File) => {
      const result = await create();
      if (!result || !result.data) return;

      const fileSize = file.size;
      const partSize = 5 * 1024 * 1024;
      const totalParts = Math.ceil(fileSize / partSize);

      const fileParts: Blob[] = [];
      for (let i = 0; i < totalParts; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, fileSize);
        const blob = file.slice(start, end);
        fileParts.push(blob);
      }

      setParts(fileParts);
      getPartUrls({ bucketName, path, uploadId: result.data.uploadId, partsLength: fileParts.length });
    },
    [create, getPartUrls, bucketName, path],
  );

  return {
    upload: startUpload,
    progress,
  };
}
