import { useState } from 'react';

import { useDebounce } from '@/components/hooks/useDebounce';
import useEventState from '@/components/hooks/useEventState';
import { useInterval } from '@/components/hooks/useInterval';
import { Input } from '@/components/ui/input';
import { Progress } from '@/components/ui/progress';
import { trpc } from '@/lib/trpc';
import { cn } from '@/lib/utils/twutils';
import type { PartialProgressTrackSchema } from '@/server/q/progess';

export default function TrackProgress() {
  const [search, setSearch] = useState<string>();
  const searchValue = useDebounce(search, 200);

  const { data } = trpc.QProgressTrack.list.useQuery(searchValue);

  return (
    <div className="space-y-3">
      <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
      <div className="overflow-scroll h-full w-full space-y-4">
        {data?.map((prog) => {
          return <TrackerCard key={prog.id} prog={prog} />;
        })}
      </div>
    </div>
  );
}

function TrackerCard(props: { prog: PartialProgressTrackSchema }) {
  let prog = props.prog;

  const state = useEventState(prog.linked_event_id);

  const { data, refetch } = trpc.QProgressTrack.get.useQuery({ id: prog.id }, { enabled: false, staleTime: 0 });

  const waiting = state === ('processing' || state === 'waiting') ? true : false;
  const completed = state === ('completed' || state === 'failed') ? true : false;

  if (data) {
    prog = data;
  }

  let interval = 99999999999;
  if (waiting) {
    interval = 1000;
  }
  if (!waiting && Number(prog.completion_perc) < 100) {
    interval = 5000;
  }
  if (completed || Number(prog.completion_perc) >= 100) {
    interval = 30_000;
  }

  useInterval(refetch, interval);

  return (
    <div className={cn('rounded border p-5 space-y-3', { 'border-red-600/50': state === 'failed' })}>
      <div className="flex justify-between">
        <span>{prog.name}</span>
        <span>
          {prog.completion_index}/{prog.total} ({prog.completion_perc}%)
        </span>
      </div>
      <Progress value={Number(prog.completion_perc)} />
    </div>
  );
}
