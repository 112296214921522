'use client';

import * as CheckboxPrimitve from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { cn } from '@/lib/utils/twutils';

const CheckboxRoot = CheckboxPrimitve.Root;

const CheckboxIndicator = CheckboxPrimitve.Indicator;

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitve.Root>, React.ComponentPropsWithoutRef<typeof CheckboxPrimitve.Root>>(
  ({ className, ...props }, ref) => (
    <div className="flex">
      <CheckboxRoot
        ref={ref}
        className={cn(
          'bg-black border-2 border-white w-6 h-6 rounded-md flex items-center justify-center shadow-[0_2px_10px_var(--gray-a7)] focus:shadow-[0_0_0_2px_black]',
          className,
        )}
        {...props}
      >
        <CheckboxIndicator className="text-white">
          <CheckIcon />
        </CheckboxIndicator>
      </CheckboxRoot>
      <label className="ml-1 text-md" htmlFor="c1">
        {props.children}
      </label>
    </div>
  ),
);

Checkbox.displayName = CheckboxPrimitve.Root.displayName;

export { CheckboxRoot, CheckboxIndicator, Checkbox };
