import { trpc } from '@/lib/trpc';

const fiveSeconds = 1000 * 5;

export default function useEvent(id?: string | null) {
  return trpc.getEvent.useQuery(
    { id: id! },
    {
      enabled: !!id,
      refetchInterval: fiveSeconds,
      staleTime: fiveSeconds,
    },
  );
}
