import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils/twutils';

type Option = {
  value: string;
  label: string;
};

type ComboboxProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  onNewValue?: (value: string) => void;
  placeholder: string;
  emptyMessage?: string;
  className?: string;
  searchByLabel?: boolean;
};

export const ComboBox = ({ options, value, onChange, onNewValue, placeholder, emptyMessage, className, searchByLabel = false }: ComboboxProps) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const onSelect = useCallback(
    (currentValue: string) => {
      onChange(currentValue);
      setOpen(false);
    },
    [onChange],
  );

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        searchByLabel ? option.label.toLowerCase().includes(searchQuery.toLowerCase()) : option.value.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [options, searchQuery, searchByLabel],
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className={cn('w-full justify-between', className)}>
          {value ? options.find((option) => option.value === value)?.label : placeholder}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('p-0 w-full min-w-[var(--radix-popover-trigger-width)] max-w-[var(--radix-popover-content-available-width)]', className)}>
        <Command className="w-full">
          <CommandInput placeholder={`Search ${placeholder.toLowerCase()}`} className="h-9" onValueChange={setSearchQuery} />
          <CommandList>
            <CommandEmpty>{emptyMessage}</CommandEmpty>
            <CommandGroup>
              {filteredOptions.map((option) => (
                <CommandItem
                  key={option.value}
                  onSelect={() => {
                    onSelect(option.value);
                    if (option.value === '+ New Field' && onNewValue) {
                      onNewValue(option.value);
                    }
                  }}
                >
                  {option.label}
                  <CheckIcon className={cn('ml-auto h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
