'use client';

import { keepPreviousData, MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { httpLink } from '@trpc/client';
import { useState } from 'react';

import { trpc } from '@/lib/trpc';

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = (p) => {
  const [queryClient] = useState(createQueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: `${getBaseUrl()}/api/trpc`,
          fetch: (url, options) => fetch(url, { ...options, cache: 'no-cache' }),
        }),
      ],
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools client={queryClient} position="bottom" />
        {p.children}
      </QueryClientProvider>
    </trpc.Provider>
  );
};

function getBaseUrl() {
  if (typeof window !== 'undefined') return '';
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;
  if (process.env.RENDER_INTERNAL_HOSTNAME) return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

function createQueryClient() {
  const queryClient: QueryClient = new QueryClient({
    mutationCache: new MutationCache({
      // On every mutation, we mark all queries as stale.
      // That way, we don't need to worry about manually invalidating queries.
      // https://tkdodo.eu/blog/automatic-query-invalidation-after-mutations#the-global-cache-callbacks
      onSuccess: () => queryClient.invalidateQueries(),
    }),
    defaultOptions: {
      queries: {
        staleTime: 1 * 60 * 1000, // 1 minute
        gcTime: 1 * 60 * 60 * 1000, // 1 hour
        refetchOnWindowFocus: true,
        refetchOnReconnect: 'always',
        refetchOnMount: true,
        placeholderData: keepPreviousData,
      },
    },
  });
  return queryClient;
}
