'use client';
import { PlusIcon } from '@radix-ui/react-icons';
import _ from 'lodash';
import { HomeIcon } from 'lucide-react';
import Link from 'next/link';
import { useParams, usePathname, useRouter } from 'next/navigation';
import React from 'react';

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from '@/components/ui/menubar';
import type { ConfigTypes } from '@/lib/configs/types';
import { trpc } from '@/lib/trpc';
import { toTitleCase } from '@/lib/utils/frontend';

import useSharedStackView from '../hooks/useSharedStackView';
import { Button } from '../ui/button';
import { ComboBox } from '../ui/combo-box';
import { LoadingSpinner } from '../ui/LoadingSpinner';

const ClientMenubarMenu = () => {
  const { data: clients, isLoading } = trpc.getClients.useQuery();

  return (
    <MenubarMenu>
      <MenubarTrigger>Clients</MenubarTrigger>
      <MenubarContent>
        <div className="max-h-[800px] overflow-scroll border border-slate-600 rounded">
          <Link className="text-white no-underline hover:text-black" href="/clients/new">
            <MenubarItem className="hover:text-black text-base pl-4 pr-4">
              <PlusIcon className="h-5 w-5 mr-2" />
              New client
            </MenubarItem>
          </Link>

          {!isLoading ? (
            <ComboBox
              options={
                clients?.map((client) => ({ value: client.index.toString(), label: `${_.startCase(client.name!)} (${client.index.toString()})` ?? '' })) ?? []
              }
              value={''}
              onChange={(e) => {
                if (clients && clients.length > 0) {
                  const clientName = clients.find((client) => client.index.toString() === e)?.name;
                  window.location.href = `/clients/${clientName}`;
                }
              }}
              placeholder="Select client"
              className="w-[350px]"
              searchByLabel={true}
            />
          ) : (
            <LoadingSpinner className="h-6 w-6 my-auto text-white" />
          )}
        </div>
      </MenubarContent>
    </MenubarMenu>
  );
};

export const MenuBar = () => {
  const params = useParams<{ client: string }>();
  const { data: flows } = trpc.flows.configs.list.useQuery({ client: params?.client! }, { enabled: !!params?.client });

  const { show } = useSharedStackView();

  const path = usePathname();

  if (path?.includes('landing')) return <div></div>;

  return (
    <div className="flex flex-row justify-between border-0 border-b-2  h-[42px]">
      <Menubar>
        <MenubarMenu>
          <Link href={`/clients/${params?.client}`}>
            <HomeIcon className="h-4 w-4 ml-2 mr-0.5 -mt-0.5 cursor-pointer" />
          </Link>
        </MenubarMenu>
        <ClientMenubarMenu />
        <MenubarMenu>
          <MenubarTrigger>Web</MenubarTrigger>
          <MenubarContent>
            {params?.client && <ConfigMenuItem config_type="classify" client={params?.client} />}
            {params?.client && <ConfigMenuItem config_type="path_finder" client={params?.client} />}
            {params?.client && <ConfigMenuItem config_type="relevant_text" client={params?.client} />}
          </MenubarContent>
        </MenubarMenu>
        {params?.client && (
          <MenubarMenu>
            <MenubarTrigger>Flows</MenubarTrigger>
            <MenubarContent>
              {flows?.map((flow) => {
                return (
                  <Link key={flow.id} className="text-inherit no-underline" href={`/clients/${params?.client}/workflows/${flow.id}`}>
                    <MenubarItem className="no-underline text-inherit text-white">{flow.name}</MenubarItem>
                  </Link>
                );
              })}
              <MenubarSeparator />
              <Link className="text-inherit no-underline" href={`/clients/${params?.client}/workflows/new`}>
                <MenubarItem className="no-underline text-inherit text-white">New flow</MenubarItem>
              </Link>
            </MenubarContent>
          </MenubarMenu>
        )}
        {params?.client && (
          <MenubarMenu>
            <MenubarTrigger>Jobs</MenubarTrigger>
            <MenubarContent>
              <Link className="text-inherit no-underline" href={`/clients/${params?.client}/relevance`}>
                <MenubarItem className="no-underline text-inherit text-white">Relevance config</MenubarItem>
              </Link>
              <Link className="text-inherit no-underline" href={`/clients/${params?.client}/technology-keywords`}>
                <MenubarItem className="no-underline text-inherit text-white">Technology keywords config</MenubarItem>
              </Link>
            </MenubarContent>
          </MenubarMenu>
        )}
        {params?.client && (
          <MenubarMenu>
            <MenubarTrigger>People</MenubarTrigger>
            <MenubarContent>
              <MenubarSub>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/people-metrics`}>
                  <MenubarItem className="no-underline text-inherit text-white">People metrics config</MenubarItem>
                </Link>
              </MenubarSub>
              <MenubarSub>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/people-search`}>
                  <MenubarItem className="no-underline text-inherit text-white">People search config</MenubarItem>
                </Link>
              </MenubarSub>
            </MenubarContent>
          </MenubarMenu>
        )}
        {params?.client && (
          <MenubarMenu>
            <MenubarTrigger>Rulesets</MenubarTrigger>
            <MenubarContent>
              <Link className="text-inherit no-underline" href={`/clients/${params?.client}/rulesets`}>
                <MenubarItem className="no-underline text-inherit text-white">Rulesets config</MenubarItem>
              </Link>
            </MenubarContent>
          </MenubarMenu>
        )}
        {params?.client && (
          <MenubarMenu>
            <MenubarTrigger>Contacts</MenubarTrigger>
            <MenubarContent>
              <Link className="text-inherit no-underline" href={`/clients/${params?.client}/contacts`}>
                <MenubarItem className="no-underline text-inherit text-white">Contacts config</MenubarItem>
              </Link>
            </MenubarContent>
          </MenubarMenu>
        )}
        <MenubarMenu>
          <MenubarTrigger>Links</MenubarTrigger>
          <MenubarContent>
            <Link className="text-inherit no-underline" href="/q/board">
              <MenubarItem className="no-underline text-inherit text-white">Events</MenubarItem>
            </Link>
            <Link className="text-inherit no-underline" href="/prompts">
              <MenubarItem className="no-underline text-inherit text-white">Prompts</MenubarItem>
            </Link>
            <Link className="text-inherit no-underline" href="/suspicious-domains">
              <MenubarItem className="no-underline text-inherit text-white">Suspicious Domains</MenubarItem>
            </Link>
            <Link className="text-inherit no-underline" href={`/clients/${params?.client}/net-new`}>
              <MenubarItem className="no-underline text-inherit text-white">Net New</MenubarItem>
            </Link>
            {params?.client && (
              <>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/export-sheets`}>
                  <MenubarItem className="no-underline text-inherit text-white">Sheet Exporter</MenubarItem>
                </Link>
                <Link className="text-inherit no-underline" href="/config">
                  <MenubarItem className="no-underline text-inherit text-white">Config Editor</MenubarItem>
                </Link>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/config/`}>
                  <MenubarItem className="no-underline text-inherit text-white">Pulled CRM Fields</MenubarItem>
                </Link>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/config/refresh`}>
                  <MenubarItem className="no-underline text-inherit text-white">Refresh Schedules</MenubarItem>
                </Link>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/criteria/`}>
                  <MenubarItem className="no-underline text-inherit text-white">Criteria Sheet</MenubarItem>
                </Link>
                <Link className="text-inherit no-underline" href={`/clients/${params?.client}/fields/`}>
                  <MenubarItem className="no-underline text-inherit text-white">Fields</MenubarItem>
                </Link>
              </>
            )}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      <div className="my-1 mr-1 space-x-3">
        <Button variant={'ghost'} onClick={() => show('event-tracker')} className="h-full">
          Event Tracker
        </Button>
        <Button variant={'ghost'} onClick={() => show('progress-track')} className="h-full">
          Progress Tracker
        </Button>
      </div>
    </div>
  );
};

function ConfigMenuItem(props: { config_type: ConfigTypes; client: string }) {
  const { data } = trpc.configsNew.list.useQuery({ configType: props.config_type, client: props.client });

  return (
    <MenubarSub>
      <MenubarSubTrigger>{toTitleCase(props.config_type.replaceAll('_', ' '))}</MenubarSubTrigger>
      <MenubarSubContent>
        {(data || [])
          .map((item) => (
            <Link key={item.name} className="text-inherit no-underline" href={`/clients/${props?.client}/configs/${props.config_type}/${item.id}`}>
              <MenubarItem className="no-underline text-inherit text-white">{item.name}</MenubarItem>
            </Link>
          ))
          .filter(Boolean)}
        <MenubarSeparator />
        <Link className="text-inherit no-underline" href={`/clients/${props?.client}/configs/${props.config_type}`}>
          <MenubarItem className="no-underline text-inherit text-white">Add New</MenubarItem>
        </Link>
      </MenubarSubContent>
    </MenubarSub>
  );
}
