import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/BaseLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/app/app/TrpcProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuBar"] */ "/app/components/app-menu-bar/menu-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetStackProvider"] */ "/app/components/display/SheetStack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"dm_sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
